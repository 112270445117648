.flickering-cursor {
  animation: blink 1s infinite steps(1);
}

.title-anim{
  font-family: 'JetBrains Mono', monospace;
  font-size: 48px;
  text-align: left;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
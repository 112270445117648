@use "sass:math";

$height: 200px;
$font-size: 40px;

.title-container {
  display: block;
  background: rgb(79,44,167);
  background: linear-gradient(315deg, rgba(79,44,167,1) 35%, rgba(0,212,255,1) 100%);
}

.title-style {
  font-family: 'JetBrains Mono', monospace;
  font-size: $font-size;
  color: white;
  padding-top: 2 * $font-size;
  padding-bottom: 2 * $font-size;
  margin: 0;
}
.App {
  text-align: center;
}

.badge-container img {
  margin: 3px;
}

.badge-container {
  background: rgb(86,171,47);
  background: linear-gradient(135deg, rgba(86,171,47,1) 0%, rgba(168,224,99,1) 100%);
  width: 100%;
  padding: 30px 25%;
}

@media (max-width: 768px) {
  .badge-container {
    padding: 30px 0;
  }
}

.entries-container {
  background: rgb(86,171,47);
  background: linear-gradient(135deg, rgba(86,171,47,1) 0%, rgba(168,224,99,1) 100%);
  width: 100%;
  padding: 30px 0;
}

@media (max-width: 768px) {
  .entries-container {
    padding-top: 0;
  }
}


.nav-buttons {
  font-size: 1.5rem;
  font-family: 'JetBrains Mono', monospace;
}

.hashlink-item {
  color: rgba(0,0,0,0.6);
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out
}

.hashlink-item:hover {
  color: rgba(0,0,0,0.8);
}

.brand-name-text {
  font-size: 1.5rem;
  font-family: 'JetBrains Mono', monospace;
}

.coming-soon {
  color: #dddddd !important;
  cursor: not-allowed !important;
}

.coming-soon .coming-soon-tooltip {
  visibility: hidden;
  width: 140px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 12px;

  /* Position the tooltip */
  position: absolute;
  top: 50px;
  right: 150px;
  z-index: 200;
}

.coming-soon:hover .coming-soon-tooltip {
  visibility: visible;
}
.footer {
  width: 100%;
  background: #011024;
  font-family: 'JetBrains Mono', monospace;
  color: #fff;
}

.footer-container {
  display: block;
  margin: 0 auto;
  padding: 10px 0;
}

.social-distance {
  margin-right: 1em;
}

.signature {
  font-size: 0.8em;
  background: #01070f;
}

.footer-social {
  color: white;
  transition: color 0.3s ease;
}

.discord:hover {
  color: #6371fe;
}

.twitter:hover {
  color: #1d9bf0;
}

.github:hover {
  color: #888;
}

.heart{
  transition: color 0.3s ease;
}

.heart:hover {
  color: #FF0000
}
.home-container {
  background: rgb(255,43,133);
  background: linear-gradient(42deg, rgba(255,43,133,1) 0%, rgba(255,0,52,1) 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  height: calc(100vh - 68px);
  text-align: center;
  color: white;
  font-family: 'JetBrains Mono', monospace;
}

.title-anim-container{
  position:relative;
  top: calc(70vh - 68px);
  left: 20px;
  width: calc(100% - 20px);
  z-index: 1;
}

.particles {
  position: absolute;
  top: 68px;
  left: 0;
  width: 100%;
  height: calc(100vh - 68px);
  z-index: 0;
}

@media (max-width: 585px) {
  .title-anim-container{
    top: calc(60vh - 68px);
  }
}

